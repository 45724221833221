import styled from '@emotion/styled';

import { Flex } from './Flex';

export const FullScreen = styled(Flex)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
